<template>
  <div>加入我们</div>
</template>

<script>
export default {
  created() {
    this.$router.push({
      path: '/home/加入我们',
      params: {
        id: "加入我们"
      }
    })
  }
}
</script>

<style>

</style>